import React from 'react'
import clsx from 'clsx'
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Button from '../Button/Button'
import Link from '../Link/Link'
import Skeleton from '@material-ui/lab/Skeleton'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { isPrismicText, prismicText } from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(4, 0),
    },
    containerFull: {
      padding: 0,
      overflow: 'hidden',
    },
    container: {},
    textInfo: {
      background: theme.palette.primaryTransparent.light,
      height: '400px',
      display: 'flex',
    },
    image: {
      display: 'block',
      width: '100%',
      objectFit: 'cover',
      height: 'auto',
    },
    textDiv: {
      padding: theme.spacing(0, 4),
      margin: 'auto',
    },
    content: {
      '& a': {
        display: 'inline-block',
      },
      '& h2': {
        fontSize: theme.typography.pxToRem(24),
        lineHeight: 1.15,
      },
      '& h3': {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 400,
        '& strong': {
          fontWeight: 700,
        },
      },
      '&>*': {
        marginTop: 0,
        marginBottom: theme.spacing(2),
      },
      '&.left': {
        textAlign: 'left',
      },
      '&.center': {
        textAlign: 'center',
        '& ol, & ul': {
          textAlign: 'left',
        },
      },
      '&.right': {
        textAlign: 'right',
        '& ol, & ul': {
          direction: 'rtl',
        },
      },
    },
    contentFull: {
      maxWidth: '65%',
      margin: 'auto',
    },
    skeletonCta: {
      borderRadius: theme.spacing(3),
      height: 45,
      display: 'inline-block',
    },
  })
)

type TProps = {
  homePage: any
  t: any
  isMoreInfo?: boolean
}

const InformationsSection = ({ homePage, t, isMoreInfo = false }: TProps) => {
  const { trackEvent } = useMatomo()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isInfoSection = isMoreInfo
    ? homePage?.data?.more_information_section
    : homePage?.data?.information_section
  const isFullScreen = isMoreInfo
    ? homePage?.data?.more_information_fullscreen
    : homePage?.data?.information_fullscreen
  const background = isMoreInfo
    ? homePage?.data?.more_information_bg !== null
      ? homePage.data.more_information_bg
      : 'none'
    : homePage?.data?.information_bg !== null
    ? homePage.data.information_bg
    : 'none'

  return (
    <div
      className={clsx(
        'section-infos',
        'section__spacer',
        classes.root,
        !isMoreInfo ? 'section-infos--1' : 'section-infos--2'
      )}
      style={{ background: background }}
    >
      <Container
        className={isFullScreen ? classes.containerFull : classes.container}
        maxWidth={isFullScreen ? 'xl' : 'lg'}
      >
        {isInfoSection &&
        isInfoSection.length > 0 &&
        isInfoSection[0].info_text.length > 0 ? (
          isInfoSection.map((el, index) => {
            let alignContent: string
            switch (el.align_content) {
              case 'left':
                alignContent = 'left'
                break
              case 'center':
                alignContent = 'center'
                break
              case 'right':
                alignContent = 'right'
                break
              default:
                alignContent = 'right'
            }

            return (
              <Grid
                key={index}
                container
                justify={'center'}
                alignItems={'center'}
                spacing={isFullScreen ? (isMobile ? 2 : 0) : 3}
                direction={el.image_align ? 'row-reverse' : 'row'}
              >
                <Grid item md={6} xs={12}>
                  {el.info_image ? (
                    <img
                      className={`section-infos__image ${classes.image}`}
                      src={el.info_image.url}
                      width={el.info_image.width}
                      height={el.info_image.height}
                      alt=""
                    />
                  ) : (
                    <Skeleton
                      width={'100%'}
                      height={400}
                      animation={false}
                      className={`section-infos__image ${classes.image}`}
                    />
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <div
                    className={
                      isFullScreen
                        ? `section-infos__content ${classes.contentFull} ${classes.content} ${alignContent}`
                        : `section-infos__content ${classes.content} ${alignContent}`
                    }
                  >
                    {
                      <CMSText
                        data={el?.info_text}
                        defaultText={<Skeleton variant="text" />}
                      />
                    }
                    {isPrismicText(el?.button_text) && (
                      <Link href={prismicText(el?.link_button)}>
                        <a>
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            className={'section-infos__cta'}
                            onClick={() =>
                              trackEvent({
                                category: 'HomePage',
                                href: prismicText(el?.link_button),
                                action: `CTA click ${prismicText(
                                  el?.button_text
                                )}`,
                                name: `from Information section to ${prismicText(
                                  el?.link_button
                                )}`,
                              })
                            }
                          >
                            {prismicText(el?.button_text)}
                          </Button>
                        </a>
                      </Link>
                    )}
                  </div>
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Grid
            container
            justify={'center'}
            alignItems={'center'}
            spacing={3}
            direction={'row-reverse'}
          >
            <Grid item md={6} xs={12}>
              <img
                className={`section-infos__image ${classes.image}`}
                src={'/images/placeholder.jpg'}
                width={'500px'}
                height={'500px'}
                alt=""
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={`${classes.content} ${'left'}`}>
                <p>{t('texts:general:add_a_text')}</p>
                <Link href={'/shop'}>
                  <a>
                    <Button variant={'contained'} color={'primary'}>
                      {t('texts:general:add_a_text')}
                    </Button>
                  </a>
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  )
}

export default InformationsSection
